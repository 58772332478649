import requestService from "../requestService"
import store from "@/store";

export default {
	async signUp(form) {
		const response = await requestService.post(`/v1/signup`, form)
		return response?.data
	},
	async signIn(form) {
		const response = await requestService.post(`/v1/login`, form)
		return response?.data
	},
	async signInWithSocial(form) {
		const response = await requestService.post(`/v1/auth/social`, form)
		return response?.data
	},
	async refreshToken() {
		var form = new FormData();
		form.append("token", store.getters.loggedUser.token);
		let res = requestService.post(`/refresh`, form, {
			headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
		}).then((response) => {
			let result = response?.data.authorisation;
			store.dispatch('updateInfoLogged', {
				token: result.token
			})
			return response
		})
			.catch(() => {
				store.commit('clearUserLogged')
				this.$router.push('/')
			})
		return res
	},
	async resetPassword(form) {
		const response = await requestService.post(`/v1/password/email`, form)
		return response?.data
	},
	async changePassword(form) {
		const response = await requestService.post(`/v1/password/reset`, form)
		return response?.data
	}
}
export default {
    state: {
        locale: 'UA',
    },
    mutations: {
        setLocale(state, locale) {
            state.locale = locale.toUpdate
        },
    },
    actions: {
        async updateLocale({ commit, getters }, toUpdate) {
            try {
                const updateData = { ...getters.locale, toUpdate }
                commit('setLocale', updateData)
            }

            catch (e) {
                commit('setError', e)
                throw e
            }
        },
    },
    getters: {
        locale: s => s.locale
    }
}

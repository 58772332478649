<template>
  <v-footer class="footer">
    <v-main>
      <v-container>
        <v-row no-gutters align="start" v-if="!$vuetify.breakpoint.smAndDown">
          <v-col>
            <p class="footerSectionTitle">
              {{ "footer_menu_label" | localize }}
            </p>
            <router-link to="/">
              <p class="footerSectionText">{{ "header_main" | localize }}</p>
            </router-link>
            <router-link to="/routes">
              <p class="footerSectionText">{{ "header_our_routes" | localize }}</p>
            </router-link>
            <router-link to="/rent_bus">
              <p class="footerSectionText">{{ "header_rent_bus" | localize }}</p>
            </router-link>
            <router-link to="/blog">
              <p class="footerSectionText">{{ "header_blog" | localize }}</p>
            </router-link>
          </v-col>
          <!-- <v-col>
            <p class="footerSectionTitle">
              {{ "footer_information_label" | localize }}
            </p>
            <router-link to="/promocion">
              <p class="footerSectionText">Акції</p>
            </router-link>
            <router-link to="/blog">
              <p class="footerSectionText">Блог</p>
            </router-link>
            <router-link to="/news">
              <p class="footerSectionText">Новини</p>
            </router-link>
          </v-col> -->
          <v-col style="padding-right: 50px">
            <p class="footerSectionTitle">
              {{ "footer_rules_label" | localize }}
            </p>
            <router-link to="/privacy_police">
              <p class="footerSectionText">{{'footer_privacy_policy' | localize}}</p>
            </router-link>
            <router-link to="/transportation_rules">
              <p class="footerSectionText">{{'footer_transportation_rules' | localize}}</p>
            </router-link>
          </v-col>
          <v-col
            style="
              border-right: 1px solid rgba(250, 250, 250, 0.1);
              padding-left: 30px;
            "
            cols="3"
          >
            <p class="footerSectionTitle">
              {{ "footer_payment_methods_label" | localize }}
            </p>
            <div class="paymentTypeBackground">
              <img
                src="@/assets/img/paymentType/master_card.svg"
                alt="master card"
                width="140px"
                height="21px"
              />
            </div>
            <div class="paymentTypeBackground">
              <img
                src="@/assets/img/paymentType/visa.svg"
                alt="visa"
                width="140px"
                height="21px"
              />
            </div>
          </v-col>
          <v-col style="padding-left: 80px" cols="3">
            <v-row no-gutters align="center">
              <a
                v-for="social in socialList"
                :key="social.id"
                :href="social.value"
                target="_blank"
              >
                <div class="socialBackground" style="margin-right: 20px">
                  <img
                    width="22px"
                    height="22px"
                    style="border-radius: 5px"
                    :src="social?.image?.path"
                    :alt="social.key"
                  />
                </div>
              </a>

              <v-col class="px-0 py-0" cols="12" style="margin-top: 20px">
                <a
                  :href="`tel:${phone.value}`"
                  v-for="phone in phoneNumbers"
                  :key="phone.id"
                >
                  <p
                    class="footerSectionText"
                    style="font-weight: 500; margin-bottom: 8px"
                  >
                    {{ phone.value | VMask("+##(###) ## ## ###") }}
                  </p>
                </a>
              </v-col>
            </v-row>
            <img
              src="@/assets/img/PTransYellowLogo.png"
              style="margin-top: 20px"
              alt="PTrans"
              width="122px"
            />
          </v-col>
        </v-row>
        <v-col
          class="px-0 py-0"
          no-gutters
          align="start"
          v-else
          style="text-align: center"
        >
          <img
            src="@/assets/img/PTransYellowLogo.png"
            style="margin-top: 20px"
            alt="PTrans"
            width="122px"
          />
          <v-row no-gutters justify="center" style="margin: 20px 0px">
            <a
              v-for="(social, index) in socialList"
              :key="social.id"
              :href="social.value"
              target="_blank"
            >
              <div
                class="socialBackground"
                style="margin-right: 20px"
                :style="
                  index == socialList.length - 1 ? 'margin-right: 0px;' : ''
                "
              >
                <img
                  width="22px"
                  height="22px"
                  style="border-radius: 5px"
                  :src="social?.image?.path"
                  :alt="social.key"
                />
              </div>
            </a>
          </v-row>
          <v-col style="text-align: center; margin-top: 20px">
            <a
              :href="`tel:${phone.value}`"
              v-for="phone in phoneNumbers"
              :key="phone.id"
            >
              <p
                class="footerSectionText"
                style="font-weight: 600; margin-bottom: 8px"
              >
                {{ phone.value | VMask("+##(###) ## ## ###") }}
              </p>
            </a>
          </v-col>
          <v-col>
            <p class="footerSectionTitle">
              {{ "footer_rules_label" | localize }}
            </p>
            <router-link to="/privacy_police">
              <p class="footerSectionText">{{'footer_privacy_policy' | localize}}</p>
            </router-link>
            <router-link to="/transportation_rules">
              <p class="footerSectionText">{{'footer_transportation_rules' | localize}}</p>
            </router-link>
          </v-col>
          <!-- <v-col>
            <p class="footerSectionTitle">
              {{ "footer_information_label" | localize }}
            </p>
            <router-link to="/promocion">
              <p class="footerSectionText">Акції</p>
            </router-link>
            <router-link to="/blog">
              <p class="footerSectionText">Блог</p>
            </router-link>
            <router-link to="/news">
              <p class="footerSectionText">Новини</p>
            </router-link>
          </v-col> -->
          <v-col>
            <p class="footerSectionTitle">
              {{ "footer_menu_label" | localize }}
            </p>
            <router-link to="/">
              <p class="footerSectionText">{{ "header_main" | localize }}</p>
            </router-link>
            <router-link to="/routes">
              <p class="footerSectionText">{{ "header_our_routes" | localize }}</p>
            </router-link>
            <router-link to="/rent_bus">
              <p class="footerSectionText">{{ "header_rent_bus" | localize }}</p>
            </router-link>
            <router-link to="/blog">
              <p class="footerSectionText">{{ "header_blog" | localize }}</p>
            </router-link>
          </v-col>
          <v-col cols="12">
            <p class="footerSectionTitle">
              {{ "footer_payment_methods_label" | localize }}
            </p>
            <div class="paymentTypeBackground" style="width: 100%">
              <img
                src="@/assets/img/paymentType/master_card.svg"
                alt="master card"
                height="21px"
                width="max-content"
              />
            </div>
            <div class="paymentTypeBackground" style="width: 100%">
              <img
                src="@/assets/img/paymentType/visa.svg"
                alt="visa"
                height="21px"
                width="max-content"
              />
            </div>
          </v-col>
        </v-col>
      </v-container>
    </v-main>
  </v-footer>
</template>
  
  <script>
import { mapActions } from 'vuex';
import settingsService from "../requests/Admin/settingsService";
export default {
  name: "FooterComponent",
  data: () => ({
    phoneNumbers: [],
    socialList: [],
  }),
  mounted() {
    this.getSettings(); 
  },
  methods: {
    ...mapActions(['updateSocialList']),
    async getSettings() {
      await settingsService.getSettingList().then((res) => {
        this.socialList = res.data.filter((social) => social.type == "Socials");
        this.updateSocialList(this.socialList)
        this.phoneNumbers = res.data.filter(
          (phone) => phone.type == "Dispatchers" || phone.type == "Contact"
        );
        localStorage.setItem(
          "bonus_amount",
          res.data.find((social) => social.key == "bonus_amount")?.value
        );
      });
    },
  },
};
</script>
  
  <style scoped>
.footer {
  background: #172b69 !important;
  padding: 40px 0px;
}
.footerSectionTitle {
  color: #fafafa;
  font-family: "MacPaw Fixel Display";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 12px;
}
@media only screen and (max-width: 1023px) {
  .footerSectionTitle {
    font-weight: 600 !important;
  }
}
.footerSectionText {
  color: #fafafa;
  font-family: "MacPaw Fixel Display";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 12px;
}
.paymentTypeBackground {
  border-radius: 4px;
  background: #fafafa;
  display: grid;
  place-items: center;
  height: 45px;
  margin-bottom: 12px;
  width: 140px;
}
.socialBackground {
  background: #fafafa1a;
  width: 32px;
  height: 32px;
  border-radius: 10px;
  display: grid;
  place-items: center;
  cursor: pointer;
}
</style>
import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store'
Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'main',
        component: () => import('../views/User/Main.vue')
    },
    {
        path: '/routes',
        name: 'routes',
        component: () => import('../views/User/Routes.vue')
    },
    {
        path: '/search/:departure_city/:arrival_city',
        name: 'ticket_search',
        component: () => import('../views/User/ticketSearch.vue')
    },
    {
        path: '/passanger',
        name: 'booking_ticket',
        component: () => import('../views/User/bookingTicket.vue')
    },
    {
        path: '/route/:route_slug/:route_id',
        name: 'route_detail_page',
        component: () => import('../views/User/routeDetailPage.vue')
    },
    {
        path: '/our_fleet',
        name: 'our_fleet',
        component: () => import('../views/User/ourFleet.vue')
    },
    {
        path: '/rent_bus',
        name: 'rent_bus',
        component: () => import('../views/User/rentBus.vue')
    },
    {
        path: '/blog',
        name: 'blog',
        component: () => import('../views/User/Blog.vue')
    },
    {
        path: '/blog/:slug',
        name: 'blog_detail_page',
        component: () => import('../views/User/BlogDetailPage.vue')
    },
    {
        path: '/profile',
        name: 'user_profile',
        component: () => import('../views/User/myProfile.vue'),
        meta: { requiresAuth: true },
        children: [
            {
                path: 'my-ticket',
                name: 'profileMyTicket',
                component: () => import('@/components/User/userProfile/myTicket/myTicketComponent.vue')
            },
            {
                path: 'bonuses',
                name: 'profileBonuses',
                component: () => import('@/components/User/userProfile/bonuses/bonusesComponent.vue')
            },
            {
                path: 'setting',
                name: 'profileSetting',
                component: () => import('@/components/User/userProfile/profileSettingComponent.vue')
            },
        ]
    },
    {
        path: '/admin',
        name: 'admin',
        component: () => import('../views/Admin/Admin.vue'),
        redirect: '/admin/main',
        meta: { requiresAuth: true, roles: ['admin', 'manager', 'accountant', 'dispatcher'] },
        children: [
            {
                path: 'main',
                name: 'mainComponent',
                component: () => import('@/components/Admin/Main/mainCharts/mainComponent.vue'),
                meta: { roles: ['admin', 'manager', 'accountant', 'dispatcher'] }
            },
            {
                path: 'about-us',
                name: 'aboutUsComponent',
                component: () => import('@/components/Admin/Main/aboutUsComponent.vue'),
                meta: { roles: ['admin', 'manager'] }
            },
            {
                path: 'admin-header',
                name: 'adminHeader',
                component: () => import('@/components/Admin/adminHeader.vue'),
                meta: { roles: ['admin', 'manager', 'accountant'] }
            },
            {
                path: 'admin-navigation-drawer',
                name: 'adminNavigationDrawer',
                component: () => import('@/components/Admin/adminNavigationDrawer.vue'),
                meta: { roles: ['admin', 'manager', 'accountant', 'dispatcher'] }
            },
            {
                path: 'contact',
                name: 'contactComponent',
                component: () => import('@/components/Admin/Main/contactComponent.vue'),
                meta: { roles: ['admin', 'manager'] }
            },
            {
                path: 'cities',
                name: 'citiesComponent',
                component: () => import('@/components/Admin/Routes/Cities/citiesComponent.vue'),
                meta: { roles: ['admin', 'manager'] }
            },
            {
                path: 'routes',
                name: 'routesComponent',
                component: () => import('@/components/Admin/Routes/routesComponent.vue'),
                meta: { roles: ['admin', 'manager', 'accountant', 'dispatcher'] },
                children: [
                    {
                        path: 'create-route',
                        name: 'createRoute',
                        meta: { roles: ['admin', 'manager'] },
                        component: () => import('@/components/Admin/Routes/createNewRouteComponent.vue'),
                    }, {
                        path: 'edit-route/:id',
                        name: 'editRoute',
                        meta: { roles: ['admin', 'manager'] },
                        component: () => import('@/components/Admin/Routes/createNewRouteComponent.vue'),
                    }
                ]
            },
            {
                path: 'prices',
                name: 'pricesComponent',
                component: () => import('@/components/Admin/Routes/Prices/pricesComponent.vue'),
                meta: { roles: ['admin', 'manager', 'accountant'] }
            },
            {
                path: 'prices/:route_id',
                name: 'pricesEditComponent',
                component: () => import('@/components/Admin/Routes/Prices/pricesComponent.vue'),
                meta: { roles: ['admin', 'manager', 'accountant'] }
            },
            {
                path: 'trips',
                name: 'tripsComponent',
                component: () => import('@/components/Admin/Trips/tripsComponent.vue'),
                meta: { roles: ['admin', 'manager', 'accountant', 'dispatcher'] },
                children: [
                    {
                        path: 'create-trip',
                        name: 'createTrip',
                        meta: { roles: ['admin', 'manager'] },
                        component: () => import('@/components/Admin/Trips/createNewTripComponent.vue'),
                    }, {
                        path: 'edit-trip/:id',
                        name: 'editTrip',
                        meta: { roles: ['admin', 'manager'] },
                        component: () => import('@/components/Admin/Trips/createNewTripComponent.vue'),
                    }
                ]
            },
            {
                path: 'order-history',
                name: 'orderHistory',
                component: () => import('@/components/Admin/orderHistory/orderHistoryComponent.vue'),
                meta: { roles: ['admin', 'manager', 'dispatcher'] }
            },
            {
                path: 'reviews',
                name: 'reviewsComponent',
                component: () => import('@/components/Admin/Main/Reviews/reviewsComponent.vue'),
                meta: { roles: ['admin', 'manager'] }
            },
            {
                path: 'faq',
                name: 'faqComponent',
                component: () => import('@/components/Admin/Main/FAQ/FAQComponent.vue'),
                meta: { roles: ['admin', 'manager'] }
            },
            {
                path: 'autopark',
                name: 'autoparkComponent',
                component: () => import('@/components/Admin/Autopark/autoparkComponent.vue'),
                meta: { roles: ['admin', 'manager'] },
                children: [
                    {
                        path: 'create-autopark',
                        name: 'createAutopark',
                        component: () => import('@/components/Admin/Autopark/createNewBus.vue'),
                    }, {
                        path: 'edit-autopark/:id',
                        name: 'editAutopark',
                        component: () => import('@/components/Admin/Autopark/createNewBus.vue'),
                    }
                ]
            },
            {
                path: 'blog',
                name: 'blogComponent',
                component: () => import('@/components/Admin/Blog/blogComponent.vue'),
                meta: { roles: ['admin', 'manager'] },
                children: [
                    {
                        path: 'create-blog',
                        name: 'createBlog',
                        component: () => import('@/components/Admin/Blog/createNewBlog.vue'),
                    }, {
                        path: 'edit-blog/:id',
                        name: 'editBlog',
                        component: () => import('@/components/Admin/Blog/createNewBlog.vue'),
                    }
                ]
            },
            {
                path: 'message',
                name: 'messageComponent',
                component: () => import('@/components/Admin/Message/messageComponent.vue'),
                meta: { roles: ['admin', 'manager', 'dispatcher'] }
            },
            {
                path: 'promotion',
                name: 'promotionComponent',
                component: () => import('@/components/Admin/Promotions/promotionComponent.vue'),
                meta: { roles: ['admin', 'manager'] }
            },
            {
                path: 'promocode',
                name: 'promocodeComponent',
                component: () => import('@/components/Admin/Promotions/Promocode/promocodeComponent.vue'),
                meta: { roles: ['admin', 'manager'] }
            },
            {
                path: 'stations',
                name: 'stationsComponent',
                component: () => import('@/components/Admin/Routes/Stations/stationsComponent.vue'),
                meta: { roles: ['admin', 'manager'] }
            },
            {
                path: 'social',
                name: 'socialComponent',
                component: () => import('@/components/Admin/Promotions/Social/socialComponent.vue'),
                meta: { roles: ['admin', 'manager'] }
            },
            {
                path: 'users',
                name: 'usersComponent',
                component: () => import('@/components/Admin/Users/usersComponent.vue'),
                meta: { roles: ['admin', 'manager', 'accountant', 'dispatcher'] },
            },
            {
                path: 'setting-admin',
                name: 'settingComponent',
                component: () => import('@/components/Admin/settingAdmin.vue'),
                meta: { roles: ['admin', 'manager', 'accountant', 'dispatcher'] },
            },
            {
                path: 'workers',
                name: 'workerComponent',
                component: () => import('@/components/Admin/Workers/workersComponent.vue'),
                meta: { roles: ['admin', 'manager', 'accountant'] },
            },
            {
                path: 'worker/:id',
                name: 'detailWorkerComponent',
                component: () => import('@/components/Admin/Workers/detailWorker/detailWorkerPage.vue'),
                meta: { roles: ['admin', 'accountant'] },
            },
            {
                path: 'reports',
                name: 'reportsComponent',
                component: () => import('@/components/Admin/Reports/reportsComponent.vue'),
                meta: { roles: ['admin', 'manager', 'accountant', 'dispatcher'] },
            },
            {
                path: 'liqpay',
                name: 'liqpayComponent',
                component: () => import('@/components/Admin/Payments/liqpayComponent.vue'),
                meta: { roles: ['admin', 'manager', 'accountant'] },
            },
            {
                path: 'blink',
                name: 'blinkComponent',
                component: () => import('@/components/Admin/Payments/blinkComponent.vue'),
                meta: { roles: ['admin', 'manager', 'accountant'] },
            },
            {
                path: 'booking',
                name: 'bookingComponent',
                component: () => import('@/components/Admin/Trips/Booking/bookingComponent.vue'),
                meta: { roles: ['admin', 'dispatcher'] },
            }
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/User/Login.vue'),
        meta: { requiresGuest: true }
    },
    {
        path: '/registration',
        name: 'registration',
        component: () => import('../views/User/Registration.vue'),
        meta: { requiresGuest: true }
    },
    {
        path: '/reset-password',
        name: 'reset_password',
        component: () => import('../views/User/resetPassword.vue'),
        meta: { requiresGuest: true }
    },
    {
        path: '/auth/password/email/reset',
        name: 'reset_password_code',
        component: () => import('@/views/User/inputNewPassword.vue'),
        meta: { requiresGuest: true }
    },
    {
        path: '/bus_qr/:bus_id',
        name: 'bus_by_qr_code',
        component: () => import("@/views/User/busByQrCode.vue")
    },
    {
        path: '/order_qr/:order_id',
        name: 'order_by_qr_code',
        component: () => import("@/views/User/orderByQrCode.vue")
    },
    {
        path: '/success/payment/:order_id',
        name: 'success_page',
        component: () => import("@/views/User/paymentSuccess.vue")
    },
    {
        path: '/order_detail/:order_id',
        name: 'order_detail',
        component: () => import("@/views/User/orderDetailByQR.vue")
    },
    {
        path: '/privacy_police',
        name: 'privacy_police',
        component: () => import("@/components/privacyPolice.vue")
    },
    {
        path: '/transportation_rules',
        name: 'transportation_rules',
        component: () => import("@/components/transportationRules.vue")
    },
    {
        path: '/test',
        name: 'test',
        component: () => import("@/components/Admin/Trips/pdfReport.vue")
    },
]

const router = new VueRouter({
    mode: 'history',
    routes,
});

router.beforeEach((to, from, next) => {
    const isAuthenticated = store.getters.loggedUserPTrans !== null ? true : false;
    const userRole = store.getters?.loggedUserPTrans?.role;
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // Check auth
        if (!isAuthenticated) {
            next({ name: 'login' });
        } else {
            // Check user role
            if (to.meta.roles && !to.meta.roles.includes(userRole)) {
                next({ name: 'main' }); // redirect to main
            } else {
                next();
            }
        }
    } else if (to.matched.some(record => record.meta.requiresGuest)) {
        // Chech not auth user
        if (isAuthenticated) {
            next({ name: 'main' });
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;
export default {
  state: {
    socialList: null,
  },
  mutations: {
    setSocialList(state, socialList) {
      state.socialList = socialList
    },
    clearSocialList(state) {
      state.socialList = null
    },
  },
  actions: {
    async updateSocialList({ commit, getters }, toUpdate) {
      try {
       const updateData = { ...getters.socialList, ...toUpdate }
       commit('setSocialList', updateData)
      }

      catch (e) {
        commit('setError', e)
        throw e
      }
    },
  },
  getters: {
    socialList: s => s.socialList,
  }
}

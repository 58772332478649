var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[_c('v-app',[(
        _vm.$route.path !== '/' &&
        _vm.$route.name !== '/admin' &&
        _vm.$route.matched?.[0]?.name !== 'admin'
      )?_c('Header',{on:{"showNavigationDrawer":_vm.showNavigationDrawer,"showProfileNavigationDrawer":_vm.showProfileNavigationDrawer}}):_vm._e(),_c('router-view',{on:{"showNavigationDrawer":_vm.showNavigationDrawer,"showProfileNavigationDrawer":_vm.showProfileNavigationDrawer}}),(
        _vm.$route.path !== '/' &&
        _vm.$route.path !== '/admin' &&
        _vm.$route.matched?.[0]?.name !== 'admin'
      )?_c('Footer'):_vm._e(),(_vm.$route.path !== '/')?_c('footer-for-all-pages'):_vm._e(),(_vm.isShowNavigationDrawer)?_c('mobile-navigation-drawer',{on:{"close":function($event){_vm.isShowNavigationDrawer = false}}}):_vm._e(),(_vm.isShowProfileNavigationDrawer)?_c('profile-drawer',{on:{"close":function($event){_vm.isShowProfileNavigationDrawer = false}}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-navigation-drawer
    absolute
    top
    width="100%"
    v-model="showDrawer"
    style="
      background: rgba(250, 250, 250, 0.8);
      backdrop-filter: blur(4px);
      min-height: 100svh;
      padding: 0px 0px;
    "
  >
    <v-main>
      <v-container style="min-height: 95svh; position: relative">
        <v-row no-gutters align="center">
          <router-link to="/">
            <img
              src="@/assets/img/PTransBlackLogo.png"
              :width="$vuetify.breakpoint.smAndDown ? '107px' : '122px'"
              alt="sitelogo"
              style="object-fit: cover; margin-top: 8px"
            />
          </router-link>
          <v-row
            no-gutters
            justify="end"
            v-if="$vuetify.breakpoint.smAndDown"
            style="margin-top: 8px"
          >
            <div style="padding: 4px 12px">
              <div class="languageIcon" />
            </div>
            <div style="padding: 4px 12px">
              <div class="userMobileIcon" />
            </div>
            <div style="padding: 4px 12px">
              <div class="closeIcon" @click="$emit('close')" />
            </div>
          </v-row>
        </v-row>
        <v-col style="text-align: center; margin-top: 25svh" class="px-0 py-0">
          <router-link to="/" class="routeLinkText">
            <p style="margin-bottom: 0px">{{ "header_main" | localize }}</p>
          </router-link>

          <router-link class="routeLinkText" to="/routes">
            <p style="margin-bottom: 0px; margin-top: 32px">
              {{ "header_our_routes" | localize }}
            </p></router-link
          >
          <router-link class="routeLinkText" to="/rent_bus">
            <p style="margin-bottom: 0px; margin-top: 32px">
              {{ "header_rent_bus" | localize }}
            </p></router-link
          >
          <router-link class="routeLinkText" to="/blog">
            <p style="margin-bottom: 0px; margin-top: 32px">
              {{ "header_blog" | localize }}
            </p></router-link
          >
        </v-col>
        <v-row
          no-gutters
          justify="center"
          style="position: absolute; bottom: 0px; width: calc(100% - 20px)"
        >
          <img
            v-for="(social, index) in socialList"
            :key="social.id"
            :src="social?.image?.path"
            width="24px"
            height="24px"
            alt="social"
            :style="index == Object.values(socialList).length - 1 ? '' : 'margin-right: 20px;'"
          />
        </v-row>
      </v-container>
    </v-main>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    showDrawer: true,
  }),
  mounted(){
    console.log(Object.values(this.socialList).length)
  },
  computed: {
    ...mapGetters(["socialList"]),
  },
  watch: {
    showDrawer: {
      handler() {
        this.$emit("close");
      },
    },
  },
};
</script>

<style>
.routeLinkText {
  color: #1b1b1b !important;
  font-family: "MacPaw Fixel";
  font-size: 20px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>
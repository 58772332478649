import Vue from 'vue';
import VueI18n from 'vue-i18n';
import store from '../store';
Vue.use(VueI18n);
const i18n = new VueI18n({
    locale: store.getters.locale.toLowerCase(),
    messages: {
        en: {
            passenger: '{count} passenger | {count} passengers'
        },
        ua: {
            passenger: '{count} пасажир | {count} пасажири | {count} пасажирів'
        },
        pl: {
            passenger: '{count} pasażer | {count} pasażerowie | {count} pasażerów'
        }
    },
    pluralizationRules: {
        'ua': function (choice) {
            if (choice === 0) {
                return 2; // "пасажирів"
            }
            if (choice === 1) {
                return 0; // "пасажир"
            }
            if (choice >= 2 && choice <= 4) {
                return 1; // "пасажири"
            }
            return 2; // "пасажирів"
        },
        'pl': function (choice) {
            if (choice === 1) {
                return 0; // "pasażer"
            }
            if (choice >= 2 && choice <= 4) {
                return 1; // "pasażerowie"
            }
            return 2; // "pasażerów"
        }
    }
});

export default i18n;

<template>
  <div id="app">
    <v-app>
      <Header
        v-if="
          $route.path !== '/' &&
          $route.name !== '/admin' &&
          $route.matched?.[0]?.name !== 'admin'
        "
        @showNavigationDrawer="showNavigationDrawer"
        @showProfileNavigationDrawer="showProfileNavigationDrawer"
      />
      <router-view
        @showNavigationDrawer="showNavigationDrawer"
        @showProfileNavigationDrawer="showProfileNavigationDrawer"
      />
      <Footer
        v-if="
          $route.path !== '/' &&
          $route.path !== '/admin' &&
          $route.matched?.[0]?.name !== 'admin'
        "
      />
      <footer-for-all-pages v-if="$route.path !== '/'" />
      <mobile-navigation-drawer
        v-if="isShowNavigationDrawer"
        @close="isShowNavigationDrawer = false"
      />
      <profile-drawer
        v-if="isShowProfileNavigationDrawer"
        @close="isShowProfileNavigationDrawer = false"
      />
    </v-app>
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import FooterForAllPages from "./components/FooterForAllPages.vue";
import Header from "./components/Header.vue";
import MobileNavigationDrawer from "./components/User/mobileNavigationDrawer.vue";
import ProfileDrawer from "./components/User/profileDrawer.vue";
import { mapGetters } from "vuex";
export default {
  name: "App",
  components: {
    Footer,
    FooterForAllPages,
    Header,
    MobileNavigationDrawer,
    ProfileDrawer,
  },
  data: () => ({
    isShowNavigationDrawer: false,
    isShowProfileNavigationDrawer: false,
  }),
  methods: {
    showNavigationDrawer() {
      this.isShowNavigationDrawer = true;
    },
    showProfileNavigationDrawer() {
      this.isShowProfileNavigationDrawer = true;
    },
  },
  computed: {
    ...mapGetters(["locale"]),
  },
  watch: {
    locale: {
      handler() {
        this.$router.go();
      },
    },
  },
};
</script>

<style>
@font-face {
  font-family: "MacPaw Fixel Display";
  src: url("./assets/fonts/FixelDisplay/FixelDisplay-Medium.woff2")
      format("woff2"),
    url("./assets/fonts/FixelDisplay/FixelDisplay-Light.woff2") format("woff2"),
    url("./assets/fonts/FixelDisplay/FixelDisplay-Regular.woff2")
      format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "MacPaw Fixel";
  src: url("./assets/fonts/FixelText/FixelText-Medium.woff2") format("woff2"),
    url("./assets/fonts/FixelText/FixelText-Light.woff2") format("woff2"),
    url("./assets/fonts/FixelText/FixelText-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
#app {
  background-color: #FAFAFA;
}
</style>

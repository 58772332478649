import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import i18n from './plugins/i18n';
import router from './router'
import VueQRCodeComponent from 'vue-qrcode-component'
import VueMask from 'v-mask'
import store from './store'
import localizeFilter from './filters/localize.filter'
//import './assets/layouts/colors.scss'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import * as VueGoogleMaps from 'vue2-google-maps'
import './assets/layouts/index.scss'
import FBSignInButton from 'vue-facebook-signin-button'

Vue.config.productionTip = false

Vue.prototype.$user_role = store.getters?.loggedUserPTrans?.role;

Vue.component('qr-code', VueQRCodeComponent)
Vue.use(VueMask);
Vue.filter('localize', localizeFilter)
Vue.use(VueAwesomeSwiper)
Vue.use(FBSignInButton)
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDjv7izdGTG6V_5XF7VwqJOzbjEYYiJjKc',
    libraries: 'places',
    region: 'ES',
    language: 'UA',
  }
})

new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

export default {
    state: {
        schedule: {},
    },
    mutations: {
        setSchedule(state, schedule) {
            state.schedule = schedule.toUpdate
        },
    },
    actions: {
        async updateSchedule({ commit, getters }, toUpdate) {
            try {
                const updateData = { ...getters.schedule, toUpdate }
                commit('setSchedule', updateData)
            }

            catch (e) {
                commit('setError', e)
                throw e
            }
        },
    },
    getters: {
        schedule: s => s.schedule
    }
}

import authService from "@/requests/Auth/authService";
import { mapActions } from "vuex";
export default {
    data: () => ({
        fbSignInParams: {
            scope: "public_profile email",
            return_scopes: true,
        },
    }),
    methods: {
        ...mapActions(['updateInfoLogged']),
        async login() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                console.log("work login", this.user_info);
            }
        },
        async registration() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                console.log("work registration", this.user_info);
            }
        },
        async openGoogleSignInModal() {
            const client = window.google.accounts.oauth2.initTokenClient({
                client_id:
                    "669120037060-dmq8sm6k7r22v0fclsvptk49p450satr.apps.googleusercontent.com",// DEV API KEY
                //"669120037060-dmq8sm6k7r22v0fclsvptk49p450satr.apps.googleusercontent.com", PROD API KEY
                scope:
                    "https://www.googleapis.com/auth/userinfo.profile \
        https://www.googleapis.com/auth/userinfo.email",
                callback: "", // defined at request time
            });
            const tokenResponse = await new Promise((resolve, reject) => {
                try {
                    // Settle this promise in the response callback for requestAccessToken()
                    client.callback = (resp) => {
                        if (resp.error !== undefined) {
                            reject(resp);
                        }
                        resolve(resp);
                    };
                    client.requestAccessToken({ prompt: "consent" });
                } catch (err) {
                    console.log(err);
                }
            });
            let form = new FormData();
            form.append('provider_name', 'google');
            form.append('access_token', tokenResponse.access_token);
            await authService.signInWithSocial(form).then((res) => {
                if (res.status == 'Success') {
                    this.updateInfoLogged({
                        first_name: res.data.user_info.first_name,
                        last_name: res.data.user_info.last_name,
                        email: res.data.user_info.email,
                        role_name: res.data.user_info.role_name,
                        token: res.data.token,
                    });
                    sessionStorage.clear();
                    if (this.$vuetify.breakpoint.smAndDown) {
                        if (this.$route.query.mobileLogin) {
                            this.$router.push(`/passanger`);
                            localStorage.setItem("startDate", new Date());
                        } else {
                            this.$router.push('/')
                        }
                    } else {
                        this.$router.push('/')
                        if (this.$route.name == 'route_search_page') {
                            this.$router.push(`/passanger`);
                            localStorage.setItem("startDate", new Date());
                        }
                    }

                }
            })
        },
        async onSignInSuccess(response) {
            let form = new FormData();
            form.append('provider_name', 'facebook');
            form.append('access_token', response.authResponse.accessToken);
            await authService.signInWithSocial(form).then((res) => {
                if (res.status == 'Success') {
                    this.updateInfoLogged({
                        first_name: res.data.user_info.first_name,
                        last_name: res.data.user_info.last_name,
                        email: res.data.user_info.email,
                        role_name: res.data.user_info.role_name,
                        token: res.data.token,
                    });
                    sessionStorage.clear();
                    if (this.$vuetify.breakpoint.smAndDown) {
                        if (this.$route.query.mobileLogin) {
                            this.$router.push(`/passanger`);
                            localStorage.setItem("startDate", new Date());
                        } else {
                            this.$router.push('/')
                        }
                    } else {
                        this.$router.push('/')
                        if (this.$route.name == 'route_search_page') {
                            this.$router.push(`/passanger`);
                            localStorage.setItem("startDate", new Date());
                        }

                    }
                }
            })
        },
        onSignInError(error) {
            console.log("OH NOES", error);
        },
    }
}
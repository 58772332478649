import requestService from "../requestService";
import store from '@/store';

export default {
	async createSetting(form) {
		const response = await requestService.post(`/cp_dash/settings`, form, {
			headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
		})
		return response?.data
	},
    async updateSetting(id,form) {
		const response = await requestService.post(`/cp_dash/settings/${id}`, form, {
			headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
		})
		return response?.data
	},
    async getSettingList() {
		const response = await requestService.get(`/v1/settings`)
		return response?.data
	},
    async getSettingListForAdmin() {
		const response = await requestService.get(`/cp_dash/settings`, {
			headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
		})
		return response?.data
	},
    async getSetting(id) {
		const response = await requestService.post(`/cp_dash/setting/${id}`, {
			headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
		})
		return response?.data
	},
	async deleteSetting(id) {
		const response = await requestService.delete(`/cp_dash/settings/${id}`, {
			headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
		})
		return response?.data
	}
}
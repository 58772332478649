import store from '../store'
import en from '@/locales/en.json'
import pl from "@/locales/pl.json"
import ua from "@/locales/ua.json"

const locales = {
    'UA': ua,
    'EN': en,
    'PL': pl
}

export default function localizeFilter(key) {
    let locale = "ua"

    locale = store.getters.locale || "ua"

    return locales[locale][key]
}
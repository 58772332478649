import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import user from './modules/user';
import locale from './modules/locale'
import currency from './modules/currency';
import schedule from './modules/schedule';
import socialList from './modules/socialList';
Vue.use(Vuex);

export default new Vuex.Store({
	plugins: [createPersistedState()],
	mutations: {
		setError(state, error) {
			state.error = error
		},
		clearError(state) {
			state.error = null
		}
	},
	getters: {
		error: s => s.error
	},
	modules: {
		user,
		locale,
		currency,
		schedule, 
		socialList
	},
});
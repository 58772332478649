export default {
  state: {
    loggedUserPTrans: null,
  },
  mutations: {
    setLoggedUser(state, loggedUser) {
      state.loggedUserPTrans = loggedUser
    },
    clearUserLogged(state) {
      state.loggedUserPTrans = null
    },
  },
  actions: {
    async updateInfoLogged({ commit, getters }, toUpdate) {
      try {
        const updateData = { ...getters.loggedUser, ...toUpdate }
        commit('setLoggedUser', updateData)
      }

      catch (e) {
        commit('setError', e)
        throw e
      }
    },
  },
  getters: {
    loggedUserPTrans: s => s.loggedUserPTrans,
  }
}

<template>
  <v-footer class="footerForAllPages">
    <v-main>
      <v-container>
        <span class="footerText">© 2023-{{ new Date().getFullYear()}} P-Trans. {{ 'footer_all_right_reserved' | localize }}</span>
      </v-container>
    </v-main>
  </v-footer>
</template>

<script>
export default {};
</script>

<style scoped>
.footerForAllPages {
  background: #031755 !important;
}
.footerText {
  color: #989898;
  font-family: "MacPaw Fixel";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>
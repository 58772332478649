<template>
  <v-btn
    class="submitBtn"
    @click="$emit('click')"
    :loading="loading"
    :disabled="disabled"
  >
    <slot></slot>
  </v-btn>
</template>

<script>
export default {
  props: {
    loading: {
      require: false,
    },
    disabled: {
      require: false,
    },
  },
};
</script>

<style scoped>
.submitBtn {
  display: grid;
  height: 40px!important;
  padding: 12px 16px;
  place-items: center;
  border-radius: 10px;
  background: #144fa9 !important;
  text-transform: none;
  width: 100%;
  color: #fafafa!important;
  font-family: "MacPaw Fixel";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.submitBtn:hover {
  background: #00318b!important;
}
</style>
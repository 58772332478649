<template>
  <v-row no-gutters align="center" justify="center" style="margin-top: 40px">
    <div
      class="continueWithSocialBackground"
      style="margin-right: 16px"
      :style="full_btn ? 'width: 100%; height: 40px;margin-right: 0px;' : ''"
      @click="openGoogleSignInModal"
    >
      <img
        src="@/assets/img/socialsIcon/googleIcon.svg"
        width="24px"
        height="24px"
        alt="google"
      />
      <span style="margin-left: 10px" v-if="full_btn">{{
        "continue_with_google_label" | localize
      }}</span>
    </div>
    <fb-signin-button
      :params="fbSignInParams"
      @success="onSignInSuccess"
      @error="onSignInError"
    >
      <div
        class="continueWithSocialBackground"
        :style="full_btn ? 'width: 100%; height: 40px;margin-top: 20px;' : ''"
      >
        <img
          src="@/assets/img/socialsIcon/facebookIcon.svg"
          width="24px"
          height="24px"
          alt="facebook"
        />
        <span style="margin-left: 10px" v-if="full_btn">{{
          "continue_with_facebook_label" | localize
        }}</span>
      </div>
    </fb-signin-button>

    <!-- <div class="continueWithSocialBackground">
      <img
        src="@/assets/img/socialsIcon/appleIcon.svg"
        width="24px"
        height="24px"
        alt="apple"
      />
    </div> -->
  </v-row>
</template>

<script>
import authMixin from "../../../mixins/authMixin";
export default {
  mixins: [authMixin],
  props: {
    full_btn: {
      require: false,
    },
  },
};
</script>

<style scoped>
.continueWithSocialBackground {
  border-radius: 10px;
  border: 1px solid #989898;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 50px;
  cursor: pointer;
  user-select: none;
  color: #4f4f4f;
  font-family: "MacPaw Fixel";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
</style>